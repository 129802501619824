import "./spinner.css";
export default function Spinner() {
  return (
    <>
      <div className="h-full w-full flex justify-center items-center bg-transparent z-[99999999788]">
        <span className="loader "></span>
      </div>
    </>
  );
}
